import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiohazard, faCircleXmark, faEnvelope, faEye, faEyeSlash, faPassport, faSliders, faUnlock } from "@fortawesome/pro-solid-svg-icons";


import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import Button from "../../components/Button";

import { myToast, urls } from "../../common";
import { useUser } from "../../context/userProvider";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LoadingDots from "../../components/LoadingDots";
import Error from "../Errors";

type PropsInputs = { key: string, _password: string, confirm_password: string }

const ResetPasswordPage = () => {
    const { key } = useParams();

    const location = useLocation();
    const { user, setToken } = useUser();
    const [reset, setReset] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const schema = yup
        .object()
        .shape({
            _password: yup.string()
                .trim()
                .required('Password Required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    {
                        message: "Must contain 8 characters, one uppercase, one lowercase, one number, and one special character",
                        excludeEmptyString: true,
                    }),

            confirm_password: yup.string().when('_password', {
                is: (password: any, schema: any) => password && password.length > 0,
                then: yup
                    .string()
                    .required('Please confirm password')
                    .oneOf([yup.ref('_password')], 'Passwords do not match'),
            }),
        });


    const { register, handleSubmit, formState: { errors }, } = useForm<PropsInputs>({
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: { key, _password: '', confirm_password: '' }
    });


    const [isPasswordVisible, setIsPasswordVisible] = useState(false);


    const path = location.state?.from.pathname && !location.state.from.pathname.includes('logout') ? location.state.from.pathname : '';

    const [disabled, setDisabled] = useState<boolean>(false);

    const onSubmit = (data: PropsInputs) => {
        setDisabled(true);
        axios.put(`${urls.remoteURL}portal/reset-password/${key}`, data)
            .then((x) => {
                setDone(true)
            })
            .catch(({ response }) => {
                myToast({
                    title: 'Error',
                    message: response.data?.message || 'Please check you have filled everything in correctly.',
                    colour: 'red',
                    icon: faBiohazard
                });
            })
            .finally(() => {
                setDisabled(false);
            });
    }

    const onError = (error: any) => {
        console.log(error);
    }

    useEffect(() => {

        axios.get(`${urls.remoteURL}portal/check-token/${key}`)
            .then(() => {
            })
            .catch((error) => {
                setError(true);
            }).finally(() => {
                setReset(true);
            })

    }, [key])


    if (user) {
        return (
            <Navigate to={path} replace />
        );
    }

    if (!reset) {
        return (
            <div className="container  mt-20 py-10 flex justify-center"><LoadingDots /></div>
        )
    }

    return (
        <div className="container  mt-20 py-10">

            <div className="flex items-center justify-center">
                <div className="w-full md:w-1/2 xl:w-1/3">


                    <div className="bg-rhinoBlue-700 rounded-lg max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between">
                        <img src={'../images/white-rhino.png'} className={`max-h-12`} />
                        <FontAwesomeIcon icon={faUnlock} className="h-12 text-white" />
                    </div>

                    {!error ?
                        !done ?
                            <form className="card mt-5 p-5 md:p-10" onSubmit={handleSubmit(onSubmit, onError)}>
                                <p className="mb-4 font-semibold">Enter in a secure password...</p>
                                <div className="mb-5">
                                    <Label className={classNames("block mb-2", { 'invalid': errors._password })} htmlFor="email">
                                        New Password
                                    </Label>
                                    <Input
                                        {...register('_password')}
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder="*************"
                                        className={classNames({ 'invalid': errors._password })} />
                                    <p className="mt-1 text-sm text-red-700">{errors._password?.message}</p>
                                </div>
                                <div className="mb-5">
                                    <Label htmlFor="password" className={classNames("block mb-2", { 'invalid': errors.confirm_password })}>
                                        Confirm Password
                                    </Label>
                                    <label className="form-control-addon-within focus-within">
                                        <Input
                                            {...register('confirm_password')}
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder="*************"
                                            className="border-none"
                                        />
                                        <span className="flex items-center ">
                                            <button
                                                type="button"
                                                className="text-gray-300 dark:text-gray-700 text-xl leading-none pr-2"
                                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                            ><FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} fixedWidth /></button>
                                        </span>
                                    </label>
                                    <p className="mt-1 text-sm text-red-700">{errors.confirm_password?.message}</p>
                                </div>
                                <div className="flex items-center">
                                    <Link to="/control-panel/login" type="button" className="btn btn-gray text-sm uppercase">
                                        Login
                                    </Link>
                                    <Button type="submit" className="ml-auto  uppercase" disabled={disabled}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                            :
                            <div className="card mt-5 p-5 md:p-10 text-center">
                                <p className="mb-4 font-semibold">Your password is now reset, please login...</p>
                                <Link to="/control-panel/login" type="button" className="btn btn-rhinoBlue text-sm uppercase">
                                    Login
                                </Link>
                            </div>
                        :
                        <div className="mt-4">
                            <Error status={409} title="Out of time" hideButton={true} message="Your link has expired, please try again!" />
                        </div>
                    }
                </div >
            </div >
        </div >
    )
}

export default ResetPasswordPage;
