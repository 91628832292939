import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faDownload, faEdit, faSearch, faTrashCan, faXmark } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import numeral from "numeral";
import { formatDate } from "../../common/dates";
import DownloadDataButton from "../../components/DownloadDataButton";
import { handleAddDocument, handleBulkDeleteDocuments, handleDeleteDocument, handleDownloadFile } from "./common";
import { FolderDropdownSelect } from "./FolderDropdownList";


const DocumentsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const [folderID, setFolderID] = useState<string>('');

    const fetchURL = `${urls.remoteURL}get-documents`;

    const { isLoading, isError, data, refetch } = useQuery(`documents`, () => axios.get(fetchURL, { params: { folderID } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company documents?' };
    }


    type TableProps = {
        id: string,
        company_id: string,
        download_name: string,
        filename: string,
        name: string,
        mimetype: string,
        size: number,
        created: number,
        updated: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">Users: {info.row.original.users}</span> */}
            </>,
            header: 'Document Name',
            footer: 'Document Name',
            sortingFn: 'alphanumeric',
        }),

        // columnHelper.accessor('filename', {
        //     header: 'Filename',
        //     footer: 'Filename',
        //     sortingFn: 'alphanumeric',
        // }),

        // columnHelper.accessor('mimetype', {
        //     header: 'Type',
        //     footer: 'Type',
        //     sortingFn: 'alphanumeric',
        // }),

        columnHelper.accessor('size', {
            cell: info => `${numeral(info.getValue() / (1024 * 1024)).format('0,0.00')} MB`,
            header: 'Size',
            footer: 'Size',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('created', {
            cell: info => formatDate({ time: info.getValue() }),
            header: 'Created',
            footer: 'Created',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('updated', {
            cell: info => formatDate({ time: info.getValue() > 0 ? info.getValue() : info.row.original.created }),
            header: 'Updated',
            footer: 'Updated',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {!!info.row.original.company_id &&
                    <Tooltip content="Edit Document">
                        <Link to={`${info.getValue()}`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                    </Tooltip>
                }
                <Tooltip content="Download Document">
                    <Button color="orange"
                        onClick={() => handleDownloadFile({ documentID: info.getValue(), filename: info.row.original.download_name })}
                    ><span className="sr-only">Download Document</span><FontAwesomeIcon icon={faDownload} fixedWidth /></Button>
                </Tooltip>
                {!!info.row.original.company_id &&
                    <Tooltip content="Delete Document">
                        <Button type="button" color="red"
                            onClick={() => handleDeleteDocument(info.getValue(), refetch)}
                        ><span className="sr-only">Delete Document</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                    </Tooltip>
                }
            </div>
        )
    }

    const [search, setSearch] = useState<string>();

    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            ...row,
            updated: formatDate({ time: row.updated, formatStr: 'd/M/yyyy' }),
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        setSelectedRows(undefined);
        if (data?.data) { setDownloadData(parseDownloadData(data?.data)); }
    }, [data?.data])

    useEffect(() => { refetch(); }, [folderID])

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center  ">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div>
                        <FolderDropdownSelect value={folderID as string} onChange={setFolderID} defaultOption />
                    </div>

                    <div className="flex gap-x-2">
                        {/* Add New */}
                        <Button color="green" className="uppercase rounded-full px-6"
                            onClick={() => handleAddDocument({ cp: refetch })}
                        >
                            Add New
                        </Button>
                        <DownloadDataButton downloadFilename={`documents`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                noData: 'No documents found, please try changing folder.',
                                selectDisabled: data?.data.filter((x: { company_id: any; }) => !x.company_id).map((x: { id: any; }) => x.id),
                                selectDisabledIcon: faBuilding,
                                search, selectable: true, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteDocuments(selectedRows as string[], () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button>
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default DocumentsList;