import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, formatDate } from "../../../../common/dates";
import { handleBulkDeleteComplianceOptions, handleDeleteComplianceOption } from "../../common";
import { handleAddComplianceCertificate, handleDownloadCertificate } from "./common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { Link } from "react-router-dom";
import { useCompany } from "../../../../context/companyProvider";
import { PropsCompliance } from "../../types";
import { PropsCourses } from "../../../Courses/types";
import { PropsPeople } from "../../../People/types";
import { TruncateString } from "../../../../common/text";

const ComplianceOptionCertificateList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance } = useCompliance();


    const fetchURL = `${urls.remoteURL}item/options/${compliance?.id}/certificates`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-certificates`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance certificates?' };
    }

    type TableProps = {
        id: string,
        value: {
            filename: string,
            mimetype: string,
            size: string,
            user?: PropsPeople,
            course?: PropsCourses,
        },
        date_start: number,
        date_expire?: number,
        link: string,
        created: number,
        updated: number,
    }



    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.user.fullname', {}),
        columnHelper.accessor('value.filename', {
            cell: info => <>
                <TruncateString text={info.getValue()} />
                <hr className="block md:hidden" />
                {!!info.row.original.value.course && <span className="block md:hidden text-sm font-normal">Course: {info.row.original.value.course.name}</span>}
                {!!info.row.original.value.user && <span className="block md:hidden text-sm font-normal">Person: {info.row.original.value.user.fullname}</span>}
                {/* <span className="block md:hidden text-sm font-normal">Users: {info.row.original.users}</span> */}
            </>,
            header: 'Filename',
            footer: 'Filename',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('value.course.name', {
            cell: info => (!!info.row.original.value.course ? <>{info.row.original.value.course.name}</> : <>n/a</>),
            header: 'Course',
            footer: 'course',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'hidden md:table-cell'
            },
        }),

        columnHelper.accessor('value.user.lastname', {
            cell: info => (!!info.row.original.value.user ? <>{info.row.original.value.user.fullname}<br />{info.row.original.value.user.email}</> : <>n/a</>),
            header: 'Person',
            footer: 'Person',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'hidden md:table-cell'
            },
        }),

        columnHelper.accessor('date_start', {
            cell: info => formatDate({ time: info.getValue() }),
            header: 'Date',
            footer: 'Date',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'w-40 hidden md:table-cell'
            }
        }),

        columnHelper.accessor('date_expire', {
            cell: info => formatDate({ time: info.getValue() as number }),
            header: 'Expired',
            footer: 'Expired',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'w-40 hidden md:table-cell'
            }
        }),

        columnHelper.accessor('created', {
            cell: info => formatDate({ time: info.getValue() }),
            header: 'Uploaded',
            footer: 'Uploaded',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'w-40 hidden md:table-cell'
            }
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">

                {!!company.edit_items &&
                    <Tooltip content={'Edit Certificate'}>
                        <Link
                            className="btn btn-success"
                            to={info.getValue()}
                        ><span className="sr-only">Edit Certificate</span><FontAwesomeIcon icon={faPencil} fixedWidth /></Link>
                    </Tooltip>
                }

                <Tooltip content={'Download File'}>
                    <button
                        className="btn btn-orange"
                        onClick={() => { handleDownloadCertificate(info.getValue(), info.row.original.value.filename) }}
                    ><span className="sr-only">Download File</span><FontAwesomeIcon icon={faDownload} fixedWidth /></button>
                </Tooltip>

                {!!company.edit_items &&
                    <Tooltip content="Delete Certificate">
                        <Button type="button" color="red"
                            onClick={() => handleDeleteComplianceOption(info.getValue(), 'Certificate', () => { setSelectedRows([]); refetch(); })}
                        ><span className="sr-only">Delete Certificate</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                    </Tooltip>
                }
            </div>
        )
    }


    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            filename: row.value.filename,
            person: !!row.value.user ? row.value.user.fullname : 'n/a',
            course: !!row.value.course ? row.value.course.name : 'n/a',
            email: !!row.value.user ? row.value.user.email : 'n/a',
            mimetype: row.value.mimetype,
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
            start: formatDate({ time: row.date_start, formatStr: 'd/M/yyyy' }),
            expire: formatDate({ time: row.date_expire as number, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handleAddComplianceCertificate(compliance as PropsCompliance, refetch) }}
                            >
                                Add New
                            </Button>
                        }
                        <DownloadDataButton downloadFilename={`${compliance?.name}-certificates`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                hiddenColumns: ['value_user.fullname'],
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Certificates', () => { setSelectedRows([]); refetch(); })
                                    }} >Delete</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionCertificateList;