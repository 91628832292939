import React from "react";
import classNames from "classnames";
import { PropsFAQ } from "./types";
import { useQuery } from "react-query";
import axios from "axios";
import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import TextDisplay from "../../common/text";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

const FAQs = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { isLoading, data } = useQuery(`faqs-`, () => axios.get(`${urls.remoteURL}get-faqs`));

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between align-top" >
                {MenuElement}
                <div className="flex gap-x-2 align-top">
                    <Button color="green" className="uppercase rounded-full px-6 gap-2"
                        onClick={() => alert('TODO')}
                    >
                        Ask a Question
                        <FontAwesomeIcon icon={faComment} fixedWidth/>
                    </Button>
                </div>
            </div>
            <div className="card p-5">
                {data?.data.length > 0 ?
                    <div className="space-y-4 divide-y">
                        {data?.data.map((faq: PropsFAQ) =>
                            <div key={`faq-${faq.id}`} className="py-2">
                                <h3>{faq.question}</h3>
                                <TextDisplay text={faq.answer as string} />
                            </div>
                        )}
                    </div>
                    :


                    <h1>No FAQs?</h1>
                }
            </div>
        </>
    )
}

export default FAQs;