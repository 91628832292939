import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import { DayPicker, Matcher } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { endOfDay, format, isDate, isValid, parseISO, setHours, setMinutes, startOfDay } from "date-fns";
import { _DaysAgoD, _DaysAway, _DaysAwayD, _MonthsAgoD, _MonthsAwayD, currentYear } from "../../common/dates";
import Input from "./Input";
import Dropdown from "../Dropdown";
import Button from "../Button";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarEdit } from "@fortawesome/pro-solid-svg-icons";
import { PropsColor } from "../../common/colours";


interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean,
  className?: string,
  time?: boolean,
  inputFormat?: string,
  prev?: boolean,
  future?: boolean,
  minDate?: Date,
  maxDate?: Date,
}


const DatePickerControl = (props: UseControllerProps<FieldValues, string> & PropsInput) => {
  const { id, time, inputFormat = 'do MMMM yyyy', className, prev, future, minDate, maxDate } = props;

  const {
    field: { value, onChange }
  } = useController(props);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(value || new Date());
  const [timeValue, setTimeValue] = React.useState<string>(value ? `${format(value, 'HH')}:${format(value, 'mm')}` : '00:00');

  const dateRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value || undefined);

  const handleTypeDate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let dateValue: string = e.target.value;

    if (dateValue) {
      const parseDate = parseISO(dateValue);
      if (isValid(parseDate) && parseDate.getFullYear().toString().length === 4) {
        if (timeValue) { dateValue += `T${timeValue}`; }
        const newDate = new Date(dateValue);

        setMonth(newDate);
        onChange(newDate);
      }
    }
  }

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const timeValue: string = e.target.value;
    // setTimeValue(timeValue);
    if (!value) {
      setTimeValue(timeValue);
      return;
    }
    const [hours, minutes] = timeValue.split(':').map((str) => parseInt(str, 10));
    let newDate = new Date(value);
    newDate = setHours(newDate, hours);
    newDate = setMinutes(newDate, minutes);
    onChange(newDate);
    setTimeValue(timeValue);
  }

  const disabled: Matcher[] = [];

  if (prev) {
    disabled.push({ 'after': endOfDay(new Date()) });
  } else if (future) {
    disabled.push({ 'before': startOfDay(new Date()) });
  }

  useEffect(() => {
    if (dateRef.current && value) {
      const newDate = format(value, 'yyyy-MM-dd')
      dateRef.current.value = newDate;
    } else if (dateRef.current && !value) {
      dateRef.current.value = '';
    }
  }, [value, showDropdown])

  return (
    <Dropdown
      arrow={false}
      trigger={'manual'}
      onClickOutside={() => { setShowDropdown(false) }}
      show={showDropdown}
      // placement="auto"
      content={
        <div className="p-2 ">
          <div className="flex gap-2 divide-x">
            <DatePickerControls prev={prev} onChange={onChange} setMonth={setMonth} setShowDropdown={setShowDropdown} time={time} />
            <div>
              <DayPicker
                key={id}
                mode="single"
                captionLayout="dropdown-buttons"
                showOutsideDays
                selected={value}
                month={month}
                onMonthChange={setMonth}
                onSelect={(date) => { onChange(date); if (!time) { setShowDropdown(false); } }}
                className="!m-0 !ml-2"
                // fromDate={_MonthsAgoD(100)}
                toDate={maxDate}
                fromYear={!maxDate ? currentYear() - 10 : currentYear() - 20} toYear={!maxDate ? currentYear() + 10 : undefined}
                disabled={disabled}
              // footer={footer}
              />
              <div className="flex ml-2">
                <input
                  ref={dateRef}
                  defaultValue={inputValue || undefined}
                  type="date"
                  onChange={handleTypeDate}
                  placeholder="DD/MM/YYYY" className="form-control text-center" />
                {!!time &&
                  <div className="flex px-2">
                    <div className="ml-auto">
                      <Input
                        type="time"
                        value={timeValue}
                        onChange={handleTimeChange}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div >
      }>
      <Input value={value ? format(value, inputFormat || 'do MMMM yyyy') : 'Select Date'} readOnly className={classNames("cursor-pointer", className)} onClick={() => setShowDropdown(true)} />
    </Dropdown >

  );
}
export default DatePickerControl;





export const DatePicker = (props: {
  id: string, value: Date | undefined, onChange: React.Dispatch<React.SetStateAction<Date | undefined>>,
  className?: string, label?: string, time?: boolean, prev?: boolean, future?: boolean, inputFormat?: string,
}) => {
  const { id, value, onChange, className, time, prev, future, inputFormat = 'do MMMM yyyy' } = props;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(value || new Date());
  const [inputValue, setInputValue] = useState(value ? format(value, 'Y-M-d') : undefined);
  const dateRef = useRef<HTMLInputElement>(null);

  const [timeValue, setTimeValue] = React.useState<string>(value ? `${format(value, 'HH')}:${format(value, 'mm')}` : '00:00');

  const handleTypeDate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let dateValue: string = e.target.value;

    if (dateValue) {
      const parseDate = parseISO(dateValue);
      if (isValid(parseDate) && parseDate.getFullYear().toString().length === 4) {
        if (timeValue) { dateValue += `T${timeValue}`; }
        const newDate = new Date(dateValue);
        setMonth(newDate);
        onChange(newDate);
      }
    }
  }

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const timeValue: string = e.target.value;
    // setTimeValue(timeValue);
    if (!value) {
      setTimeValue(timeValue);
      return;
    }
    const [hours, minutes] = timeValue.split(':').map((str) => parseInt(str, 10));
    let newDate = new Date(value);
    newDate = setHours(newDate, hours);
    newDate = setMinutes(newDate, minutes);
    onChange(newDate);
    setTimeValue(timeValue);
  }

  const disabled: Matcher[] = [];

  if (prev) {
    disabled.push({ 'after': endOfDay(new Date()) });
  } else if (future) {
    disabled.push({ 'before': startOfDay(new Date()) });
  }

  useEffect(() => {
    if (dateRef.current && value) {
      const newDate = format(value, 'yyyy-MM-dd')
      dateRef.current.value = newDate;
    } else if (dateRef.current && !value) {
      dateRef.current.value = '';
    }
  }, [value])


  return (
    <Dropdown
      arrow={false}
      trigger={'manual'}
      onClickOutside={() => { setShowDropdown(false) }}
      show={showDropdown}
      // placement="auto"
      content={
        <div className="p-2 ">
          <div className="flex gap-2 divide-x">
            <DatePickerControls onChange={onChange} setMonth={setMonth} setShowDropdown={setShowDropdown} time={time} />
            <div>
              <DayPicker
                key={id}
                mode="single"
                captionLayout="dropdown-buttons" fromYear={currentYear() - 10} toYear={currentYear() + 10}
                showOutsideDays
                selected={value}
                month={month}
                onMonthChange={setMonth}
                onSelect={(date) => { onChange(date); if (!time) { setShowDropdown(false); } }}
                className="!m-0 !ml-2"
                disabled={disabled}
              // footer={footer}
              />
              <div className="flex ml-2">
                <input
                  ref={dateRef}
                  defaultValue={inputValue || undefined}
                  type="date"
                  onChange={handleTypeDate}
                  placeholder="DD/MM/YYYY" className="form-control text-center" />
                {!!time &&
                  <div className="flex px-2">
                    <div className="ml-auto">
                      <Input
                        type="time"
                        value={timeValue}
                        onChange={handleTimeChange}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div >
      }>
      <Input value={value ? format(value, inputFormat) : 'Select Date'} readOnly className={classNames("cursor-pointer", className)} onClick={() => setShowDropdown(true)} />
    </Dropdown >
  );
}

export const DatePickerButton = (props: {
  id: string, value: Date | undefined, onChange: (date: Date) => void,
  colour?: PropsColor, className?: string, label?: string, time?: boolean,
}) => {
  const { id, value, onChange, className, time, colour } = props;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(value || new Date());
  const [inputValue, setInputValue] = useState(value ? format(value, 'Y-M-d') : undefined);
  const dateRef = useRef<HTMLInputElement>(null);

  const [date, setDate] = useState<Date | undefined>(value as Date);

  const handleTypeDate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let dateValue: string = e.target.value;

    if (dateValue) {
      const parseDate = parseISO(dateValue);
      if (isValid(parseDate) && parseDate.getFullYear().toString().length === 4) {
        // if (timeValue) { dateValue += `T${timeValue}`; }
        const newDate = new Date(dateValue);
        setMonth(newDate);
        setDate(newDate);
      }
    }
  }



  // useEffect(() => {
  //   if (isDate(date) && date !== value) {
  //     onChange(date as Date);
  //   }
  // }, [date])


  useEffect(() => {
    if (dateRef.current && value && !date) {
      const newDate = format(value, 'yyyy-MM-dd')
      dateRef.current.value = newDate;
    } else if (dateRef.current && date) {
      const newDate = format(date, 'yyyy-MM-dd')
      dateRef.current.value = newDate;
    } else if (dateRef.current && !date) {
      dateRef.current.value = '';
    }
  }, [value, date])


  return (
    <Dropdown
      arrow={false}
      trigger={'manual'}
      onClickOutside={() => { setShowDropdown(false) }}
      show={showDropdown}
      // placement="auto"
      content={
        <div className="p-2 ">
          <div className="flex gap-2 divide-x">
            <DatePickerControls onChange={setDate} setMonth={setMonth} setShowDropdown={setShowDropdown} save date={date} saveDate={onChange} />
            <div>
              <DayPicker
                key={id}
                mode="single"
                captionLayout="dropdown-buttons" fromYear={currentYear() - 10} toYear={currentYear() + 10}
                showOutsideDays
                selected={date}
                month={month}
                onMonthChange={setMonth}
                onSelect={(newDate) => { setDate(newDate as Date); /* setShowDropdown(false);*/ }}
                className="!m-0 !ml-2"
              // footer={footer}
              />
              <div className="flex ml-2">
                <input
                  ref={dateRef}
                  defaultValue={inputValue || undefined}
                  type="date"
                  onChange={handleTypeDate}
                  placeholder="DD/MM/YYYY" className="form-control text-center" />

              </div>
            </div>
          </div>
        </div >
      }>
      <Button color={colour || 'blue'} onClick={() => setShowDropdown(true)}><FontAwesomeIcon icon={faCalendarEdit} /></Button>
      {/* <Input value={value ? format(value, 'do MMMM yyyy') : 'Select Date'} readOnly className={classNames("cursor-pointer", className)}  /> */}
    </Dropdown >
  );
}




const DatePickerControls = ({ onChange, setMonth, setShowDropdown, prev, time, save, date, saveDate }:
  {
    onChange: React.Dispatch<React.SetStateAction<Date | undefined>>,
    setMonth: React.Dispatch<React.SetStateAction<Date>>,
    setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>,
    prev?: boolean,
    time?: boolean,
    save?: boolean,
    date?: Date,
    saveDate?: (date: Date) => void,
  }) => {

  return (
    <div className="flex flex-col justify-between">
      <ul className="space-y-1">
        <li><Button color="white" onClick={() => { setMonth(new Date()); onChange(new Date()); if (!time) { setShowDropdown(false); } }} className="w-full block text-left">Today</Button></li>
        <li><Button color="white" onClick={() => {
          setMonth(!prev ? _DaysAwayD(1) : _DaysAgoD(1));
          onChange(!prev ? _DaysAwayD(1) : _DaysAgoD(1));
          if (!time) { setShowDropdown(false); }
        }} className="w-full block text-left">{!prev ? 'Tomorrow' : 'Yesterday'} </Button></li>
        <li><Button color="white" onClick={() => { setMonth(!prev ? _DaysAwayD(7) : _DaysAgoD(7)); onChange(!prev ? _DaysAwayD(7) : _DaysAgoD(7)); if (!time) { setShowDropdown(false); } }} className="w-full block text-left">{!prev ? 'Next' : 'Last'}  Week</Button></li>
        <li><Button color="white" onClick={() => setMonth(!prev ? _MonthsAwayD(1) : _MonthsAgoD(1))} className="w-full block text-left">{!prev ? 'Next' : 'Last'} Month</Button></li>
        <li><Button color="white" onClick={() => setMonth(!prev ? _MonthsAwayD(6) : _MonthsAgoD(6))} className="w-full block text-left">6 Months {!prev ? 'Away' : 'Ago'}</Button></li>
        <li><Button color="white" onClick={() => setMonth(!prev ? _MonthsAwayD(12) : _MonthsAgoD(12))} className="w-full block text-left">{!prev ? 'Next' : 'Last'} Year</Button></li>
      </ul>
      <div className="space-y-1">
        {!save ?
          <Button onClick={() => { setShowDropdown(false); }} className="block w-full">Close</Button> :
          <Button onClick={() => { if (saveDate) { saveDate(date as Date); } setShowDropdown(false); }} className="block w-full">Save</Button>}
      </div>
    </div>
  )
}