import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, _now, formatDate } from "../../../../common/dates";
import { handleAddComplianceRenewal } from "./common";
import { handleBulkDeleteComplianceOptions, handleDeleteComplianceOption } from "../../common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { Link } from "react-router-dom";
import { useCompany } from "../../../../context/companyProvider";

const ComplianceOptionAssetsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance } = useCompliance();

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/assets`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-assets`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance assets?' };
    }

    type TableProps = {
        id: string,
        value: {
            name: string,
            serial: number,
            frequency: number,
            // renewalDate: number,
        },
        date_start: number,
        date_expire: number,
        created: number,
        updated: number,
    }

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.name', {
            cell: info => info.getValue(),
            header: info => 'Name',
            footer: info => 'Name',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('value.serial', {
            cell: info => info.getValue(),
            header: info => 'Serial Number',
            footer: info => 'Serial Number',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('date_expire', {
            cell: info => <span className={`${info.getValue() <= _now() ? `bg-red-500` : info.getValue() <= _DaysAway(14) ? 'bg-orange-500' : 'bg-green-500'} px-2 py-1 text-white rounded-full`}>{info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a'}</span>,
            header: info => 'Expire',
            footer: info => 'Expire',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('created', {
            cell: info => info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a',
            header: () => 'Created',
            footer: () => 'Created',
            meta: {
                className: 'w-40'
            }
        }),
        ... !!company.edit_items ? [
            columnHelper.accessor('id', {
                header: 'Actions',
                footer: 'Actions',
                cell: info => actionCell(info),
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    className: 'w-28 text-center'
                }
            }),
        ] : []
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">

                <Tooltip content="Edit Asset">
                    <Link
                        className="btn btn-success"
                        to={info.getValue()}
                    ><span className="sr-only">Edit Asset</span><FontAwesomeIcon icon={faPencil} fixedWidth /></Link>
                </Tooltip>

                <Tooltip content="Delete Asset">
                    <Button type="button" color="red"
                        onClick={() => handleDeleteComplianceOption(info.getValue(), 'Renewal', () => { setSelectedRows([]); refetch(); })}
                    ><span className="sr-only">Delete Asset</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                </Tooltip>
            </div>
        )
    }

    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            created: row.created > 0 ? formatDate({ time: row.created, formatStr: 'd/M/yyyy' }) : 'n/a',
            name: row.value.name,
            serial: row.value.serial,
            start: row.date_start > 0 ? formatDate({ time: row.date_start, formatStr: 'd/M/yyyy' }) : 'n/a',
            expire: row.date_expire > 0 ? formatDate({ time: row.date_expire, formatStr: 'd/M/yyyy' }) : 'n/a',
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handleAddComplianceRenewal(compliance?.id as string, refetch) }}
                            >
                                Add New
                            </Button>
                        }
                        <DownloadDataButton downloadFilename={`${compliance?.name}-assets`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Renewals', () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionAssetsList;