import { faCheckCircle, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Label from "../../../../components/form/Label";
import { DatePicker } from "../../../../components/form/DatePicker";
import classNames from "classnames";
import { getUnixTime } from "date-fns";
import PeopleDropdownSelectControl, { PeopleDropdownSelect } from "../../../People/DropdownList";
import { PropsCompliance } from "../../types";
import CustomSelect from "../../../../components/form/CustomSelect";

type FormProps = { dateStart?: number, dateExpire?: number, person?: string, courseID?: string };

const ComplianceOptionCertificateAddForm = ({ compliance, inputID, setFiles }: { compliance: PropsCompliance, inputID: string, setFiles: (files: Blob | null) => void }) => {
    const [json, setJSON] = useState({});
    const [dragActive, setDragActive] = React.useState(false);
    const [dateStart, setDateStart] = useState<Date | undefined>(new Date());
    const [dateExpire, setDateExpire] = useState<Date | undefined>(undefined);

    const [fileSelected, setFileSelected] = React.useState(false);
    const [person, setPerson] = useState<string | undefined>();
    const [courseID, setCourseID] = useState<string | undefined>();

    useEffect(() => {
        // if (typeof updated === 'function') { updated({ dateStart, dateExpire }) }
        const newJSON: FormProps = {};

        console.log(person);

        if (person) { newJSON.person = person; }
        if (courseID) { newJSON.courseID = courseID; }
        setJSON({ ...newJSON, dateStart: dateStart ? getUnixTime(dateStart) : 0, dateExpire: dateExpire ? getUnixTime(dateExpire) : 0 });
    }, [dateStart, dateExpire, person, courseID]);

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files.length > 1) {
            alert('Please choose one file')
            return false;
        }

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleChangeFile(e.dataTransfer.files[0])
        }
    };

    const handleChangeFile = (file: Blob) => {
        setFiles(file);
        setFileSelected(true);
    }

    return (
        <form onDragEnter={handleDrag} className="text-left" onSubmit={(e) => { e.preventDefault(); }}>
            <div className="space-y-4">

                <div >
                    <Label className={classNames("block mb-2")} required >
                        Start Date
                    </Label>
                    <DatePicker
                        id={'start_date'}
                        value={dateStart as Date}
                        onChange={setDateStart}
                    />
                </div>


                <div >
                    <Label className={classNames("block mb-2")} >
                        Expiring Date
                    </Label>
                    <DatePicker
                        id={'expire_date'}
                        value={dateExpire}
                        onChange={setDateExpire}
                    />
                </div>

                <div className="mb-2">
                    <Label className={classNames("block mb-2")}>Person</Label>
                    <PeopleDropdownSelect
                        value={person}
                        // userType={['admins', 'site-admins']}
                        onChange={setPerson}
                    />
                </div>

                {compliance?.options?.includes('COURSE') &&
                    <div className="mb-4 ">
                        <Label className={`block mb-2`}>Course</Label>
                        {compliance.item_options && compliance?.item_options?.filter(x => x.element === 'COURSE').length > 0 ?
                            <CustomSelect
                                value={courseID}
                                onChange={(e) => { setCourseID(e.target.value) }}
                            >
                                <option key='none' value={undefined}>Select Course...</option>
                                {compliance?.item_options?.filter(x => x.element === 'COURSE').map((course) => (
                                    <option key={'compliance_courses_' + course.id} value={course.id}>{course.value.course.name}</option>
                                ))}
                            </CustomSelect>
                            :
                            <p className="text-muted text-sm">No Courses have been added?</p>
                        }
                    </div>

                }

                {!fileSelected ?
                    <div onDragEnter={handleDrag} className="text-center relative flex items-center justify-center w-full" >
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">PDF, DOC, XLS, PNG or JPG (MAX. 20mb)</p>
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" onChange={e => {
                                if (e.target.files) { handleChangeFile(e.target.files[0]) }
                            }} />

                        </label>
                        {dragActive && <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 opacity-50 bg-rhinoBlue-600 rounded-lg" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                    </div >
                    :
                    <div className="text-center">
                        <FontAwesomeIcon icon={faCheckCircle} size={'5x'} className={'text-green-700'} fixedWidth />
                        <p className="mt-4 text-xl  text-gray-500">File selected, click save!</p>
                        <p><button className="btn btn-danger" onClick={() => { setFiles(null); setFileSelected(false) }}><FontAwesomeIcon icon={faXmark} fixedWidth className="mr-2" /> Clear</button></p>
                    </div>
                }
            </div>
            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    )
}

export default ComplianceOptionCertificateAddForm;