import React, { useEffect, useState } from "react";
import axios from "axios";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import CustomSelect from "../../components/form/CustomSelect";

type PropsList = {
    value: string,
    label: string,
    default_option: boolean,
}


const FolderDropdownSelectControl = (props: UseControllerProps<FieldValues, string> & { what?: 'all' | 'rhino' | 'company', defaultOption?: boolean }) => {
    const {
        field: { value, onChange }
    } = useController(props);

    const fetchURL = `${urls.remoteURL}get-document-folders`;
    const params = { type: 'select', what: props?.what || 'rhino' };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    useEffect(() => {
        if (props.defaultOption && !value && !!data) {
            onChange(data?.find(x => x.default_option)?.value as string)
        }
    }, [data])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <CustomSelect
            value={value} onChange={onChange}
        >
            <option value="">{!value ? `Select Folder` : `No Folder`}</option>
            {data?.map(x => <option key={`folder-option-${x.value}`} value={x.value}>{x.label}</option>)}
        </CustomSelect>
    )
}

export default FolderDropdownSelectControl;


export const FolderDropdownSelect = ({ value, onChange, what, defaultOption }: { value: string, onChange: React.Dispatch<React.SetStateAction<string>>, what?: 'all' | 'rhino' | 'company', defaultOption?: boolean }) => {

    const fetchURL = `${urls.remoteURL}get-document-folders`;
    const params = { type: 'select', what: what || 'rhino' };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    useEffect(() => {
        if (defaultOption && !value && !!data) {
            onChange(data?.find(x => x.default_option)?.value as string)
        }
    }, [data])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }


    return (
        <CustomSelect
            value={value} onChange={(e) => onChange(e.target.value)}
        >
            <option value=''>{!value ? `Select Folder` : `No Folder`}</option>
            {data?.map(x => <option key={`folder-option-${x.value}`} value={x.value}>{x.label}</option>)}
        </CustomSelect>
    )
}