import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import axios from "axios";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { FieldValues, useForm } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBiohazard, faCheckCircle, faThumbsUp, faXmark } from '@fortawesome/pro-solid-svg-icons'

import DatePickerControl from "../../../../components/form/DatePicker";
import { myToast, urls } from "../../../../common";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { PropsComplianceOption } from "../../types";
import Button from "../../../../components/Button";
import LoadingDots from "../../../../components/LoadingDots";
import Label from "../../../../components/form/Label";
import { fromUnixTime, getUnixTime } from "date-fns";
import { Link } from "react-router-dom";
import PeopleDropdownSelectControl from "../../../People/DropdownList";
import { useCompany } from "../../../../context/companyProvider";
import { CustomSelectControl } from "../../../../components/form/CustomSelect";

const ComplianceOptionCertificateForm = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    let { optionID } = useParams();
    const { company } = useCompany();
    const [loading, setLoading] = useState(true);

    const { compliance } = useCompliance();
    const [option, setOption] = useState<PropsComplianceOption>()

    const [file, setFile] = useState<File | null>(null);
    const [dragActive, setDragActive] = React.useState(false);
    const [fileSelected, setFileSelected] = React.useState(false);

    useEffect(() => {
        axios.get(`${urls.remoteURL}item/option/${optionID}`)
            .then(({ data }: { data: PropsComplianceOption }) => {
                setOption(data);

                setValue('date_start', fromUnixTime(data.date_start as number));
                setValue('date_expire', fromUnixTime(data.date_expire as number));

                setValue('value.user_id', data.value.user_id);
                setValue('value.course_option_id', data.value.course_option_id);
            }).catch(error => {

            }).finally(() => {
                setLoading(false);
            });

    }, [compliance])

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm();

    const schema = yup.object().shape({
        attachment: yup.mixed().nullable().test("fileSize", "The file is too large", (value: { size: number }) => {
            return value ? value?.size <= 20000000 : true
        }),
    })

    const submit = (data: FieldValues) => {

        const formData = new FormData();
        if (file) { formData.append("upload", file); }
        for (const key of Object.keys(data)) {
            const value = ['date_start', 'date_expire'].includes(key) ? getUnixTime(data[key]) :
                key === 'value' ? JSON.stringify(data[key]) :
                    data[key];
            formData.append(key, value);
        }

        schema
            .validate({
                attachment: file,
            })
            .then(() => {
                axios.put(`${urls.remoteURL}item/option/${option?.id}/certificate`, formData).then(res => {
                    setFile(null); setFileSelected(false);
                    setOption((old) => ({
                        ...old,
                        date_start: data.date_start ? getUnixTime(data.date_start as unknown as Date) : 0,
                        date_expire: data.date_expire ? getUnixTime(data.date_expire as unknown as Date) : 0,
                        value: {
                            ...old?.value,
                            ...data?.value
                        }
                    }));
                }).then(() => {
                    myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
                }).catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error saving asset?', colour: 'red', icon: faBiohazard, timing: 3 })
                })
            }).catch(err => {
                myToast({ title: 'Error', colour: 'red', message: err?.message || `Fill in all required fields!`, icon: faBiohazard, timing: 3 })
                return false;
            });

    }

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files.length > 1) {
            alert('Please choose one file')
            return false;
        }

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleChangeFile(e.dataTransfer.files[0])
        }
    };

    const handleChangeFile = (file: File) => {
        setFile(file);
        setFileSelected(true);
    }



    if (loading) {
        return <LoadingDots />;
    }

    if (!option) {
        throw { status: 404, message: 'No Asset found?' }
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d as PropsComplianceOption))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        <Link className="btn btn-blue uppercase rounded-full px-6" to="../certificates" >Back</Link>
                        <Button color="green" className=" uppercase rounded-full px-6"
                            onClick={() => { }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
            <div className="md:grid md:grid-cols-2 gap-4">
                <div className="card p-5 space-y-4">
                    <div >
                        <Label className={classNames("block mb-2")}>
                            Start Date
                        </Label>
                        <DatePickerControl
                            id={'date_start'}
                            name="date_start"
                            control={control}
                            inputFormat="do MMMM yyyy"
                            className={classNames({ 'invalid': errors.date_start })}
                        />
                    </div>


                    <div >
                        <Label className={classNames("block mb-2")} >
                            Expiring Date
                        </Label>
                        <DatePickerControl
                            id={'date_expire'}
                            name="date_expire"
                            control={control}
                            inputFormat="do MMMM yyyy"
                            className={classNames({ 'invalid': errors.expire_date })}
                        />
                    </div>


                    <div className="mb-2">
                        <Label className={classNames("block mb-2")}>Person</Label>
                        <PeopleDropdownSelectControl
                            name="value.user_id"
                            control={control}
                        />
                    </div>

                    {compliance?.options?.includes('COURSE') &&
                        <div className="mb-4 ">
                            <Label className={`block mb-2`}>Course</Label>
                            {compliance.item_options && compliance?.item_options?.filter(x => x.element === 'COURSE').length > 0 ?
                                <CustomSelectControl
                                    name="value.course_option_id"
                                    control={control}
                                >
                                    <option key='none' value={undefined}>Select Course...</option>
                                    {compliance?.item_options?.filter(x => x.element === 'COURSE').map((course) => (
                                        <option key={'compliance_courses_' + course.id} value={course.id}>{course.value.course.name}</option>
                                    ))}
                                </CustomSelectControl>
                                :
                                <p className="text-muted text-sm">No Courses have been added?</p>
                            }
                        </div>
                    }

                </div>
                <div className="card p-5 space-y-4">
                    <h3>Replace File</h3>
                    {!fileSelected ?
                        <div onDragEnter={handleDrag} className="text-center relative flex items-center justify-center w-full" >
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">PDF, DOC, XLS, PNG or JPG (MAX. 20mb)</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" onChange={e => {
                                    if (e.target.files) { handleChangeFile(e.target.files[0]) }
                                }} />

                            </label>
                            {dragActive && <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 opacity-50 bg-rhinoBlue-600 rounded-lg" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                        </div >
                        :
                        <div className="text-center">
                            <FontAwesomeIcon icon={faCheckCircle} size={'5x'} className={'text-green-700'} fixedWidth />
                            <p className="mt-4 text-xl  text-gray-500">File selected, click save!</p>
                            <p><button className="btn btn-danger" onClick={() => { setFile(null); setFileSelected(false) }}><FontAwesomeIcon icon={faXmark} fixedWidth className="mr-2" /> Clear</button></p>
                        </div>
                    }


                </div>

            </div>
        </form>
    )

}

export default ComplianceOptionCertificateForm;